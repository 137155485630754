<template>
  <div>
    <div v-if="usuarioDB.data.mods.includes(8) || usuarioDB.data.role == 'ADMIN'">
      <v-card-text class="m-0 pt-0">
        <span class="subheading">Seleccione Año</span>
        <v-chip-group
          v-model="anoSeleccionado"
          active-class="deep-purple--text text--accent-4"
          mandatory
        >
          <v-chip v-for="ano in aniosArraySort" :key="ano.ano" :value="ano.ano">
            {{ ano.ano }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <h5 class="font-weight-light pb-3">Facturación</h5>
      <v-sheet class="mx-auto transparent" elevation="0">
        <v-slide-group v-model="model" class="pa-4" active-class="white" show-arrows>
          <v-slide-item v-slot="{ active, toggle }">
            

            <v-card
              :color="active ? 'green lighten-3' : 'green lighten-3'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle">
              <v-card-title>
                <v-avatar color="green">
                  <v-icon dark> mdi-briefcase-check-outline </v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
                <h6 class="white--text">Sin facturar</h6>
              </v-card-title>

              <v-card-text class="white--text ml-2">
                <h6>Cantidad: {{ fact_pendientes_data.length }}</h6>
                <h6>U.F: {{ fact_pendientes_data.filter(e => e.moneda === '1').reduce((prev, curr) => prev + curr.monto, 0).toLocaleString("es-CL") }}</h6>
                <h6>Pesos: ${{ fact_pendientes_data.filter(e => e.moneda === '2').reduce((prev, curr) => prev + curr.monto, 0).toLocaleString("es-CL") }}</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'deep-purple lighten-3' : 'deep-purple lighten-3'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="deep-purple">
                  <v-icon dark> mdi-currency-usd </v-icon>
                </v-avatar>
              </v-card-title>

              <v-card-text class="white--text" align="center">
                <h4>${{ fact_facturado.toLocaleString("es-CL") }}</h4>
              </v-card-text>
              <v-card-text class="white--text" align="center">
                <h6>Total facturado mes</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
    <div v-if="usuarioDB.data.mods.includes(5) || usuarioDB.data.role == 'ADMIN'">
      <h5 class="font-weight-light pb-3">Presupuestos</h5>
      <v-sheet class="mx-auto transparent" elevation="0">
        <v-slide-group v-model="model" class="pa-4" active-class="white" show-arrows>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'green lighten-3' : 'green lighten-3'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle">
              <v-card-title>
                <v-avatar color="green">
                  <v-icon dark> mdi-briefcase-check-outline </v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
                <h6 class="white--text">Autorizados</h6>
              </v-card-title>

              <v-card-text class="white--text ml-2">
                <h6>Cantidad: {{ pres_aprobados }}</h6>
                <h6>U.F: {{ pres_aprobados_uf.toLocaleString("es-CL") }}</h6>
                <h6>Pesos: ${{ pres_aprobados_pesos.toLocaleString("es-CL") }}</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'orange lighten-2' : 'orange lighten-2'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="orange">
                  <v-icon dark> mdi-briefcase-clock-outline </v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
                <h6 class="white--text">Pendientes</h6>
              </v-card-title>

              <v-card-text class="white--text ml-2">
                <h6>Cantidad: {{ pres_pendientes }}</h6>
                <h6>U.F: {{ pres_pendientes_uf.toLocaleString("es-CL") }}</h6>
                <h6>Pesos: ${{ pres_pendientes_pesos.toLocaleString("es-CL") }}</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
          <v-slide-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'red lighten-3' : 'red lighten-3'"
              class="ma-4 elevation-2"
              height="200"
              width="200"
              @click="toggle"
            >
              <v-card-title>
                <v-avatar color="red">
                  <v-icon dark> mdi-briefcase-remove-outline </v-icon>
                </v-avatar>
                <v-spacer></v-spacer>
                <h6 class="white--text">Rechazados</h6>
              </v-card-title>

              <v-card-text class="white--text ml-2">
                <h6>Cantidad: {{ pres_rechazados.toLocaleString("es-CL") }}</h6>
                <h6>U.F: {{ pres_rechazados_uf.toLocaleString("es-CL") }}</h6>
                <h6>Pesos: ${{ pres_rechazados_pesos.toLocaleString("es-CL") }}</h6>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
      <div>
        <v-card
              :color="active ? 'white' : 'white'"
              class="ma-4 elevation-2"
            >
            <v-card-title style="backgroundColor:#AFD4AB">
              <h6 class="white--text">Autorizados</h6>
              <v-spacer></v-spacer>
                <v-avatar color="green">
                  <v-icon dark> mdi-briefcase-check-outline </v-icon>
                </v-avatar>
                
                
              </v-card-title>
          <v-row>
            <v-col cols="3" lg="3" style="font-size:12px;">
              <v-row style="padding-left:15px; font-weight: bold;">
                <v-col>
                  Proyectos
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Afectos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito:       {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit:     {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total:     {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Exentos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:15px; font-weight: bold;">
                <v-col>
                  ITO
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Afectos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Exentos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9" lg="9">
              <canvas style="backgroundColor: #FFFFFF" id="presupuestos_graph" height="500"></canvas>
            </v-col>
          </v-row>
          </v-card>
      </div>
      <div style="margin-top:50px;">
        <v-card
              :color="active ? 'white' : 'white'"
              class="ma-4 elevation-2"
            >
            <v-card-title style="backgroundColor:#F5B961">
              <h6 class="white--text">Pendientes</h6>
              <v-spacer></v-spacer>
                <v-avatar color="orange">
                  <v-icon dark> mdi-briefcase-clock-outline </v-icon>
                </v-avatar>
                
                
              </v-card-title>
          <v-row>
            <v-col cols="3" lg="3" style="font-size:12px;">
              <v-row style="padding-left:15px; font-weight: bold;">
                <v-col>
                  Proyectos
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Afectos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito:       {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit:     {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total:     {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Exentos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:15px; font-weight: bold;">
                <v-col>
                  ITO
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Afectos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Exentos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9" lg="9">
              <canvas style="backgroundColor: #FFFFFF" id="presupuestos_graph_pendientes" height="500"></canvas>
            </v-col>
          </v-row>
          </v-card>
      </div>
      <div style="margin-top:50px;">
        <v-card
              :color="active ? 'white' : 'white'"
              class="ma-4 elevation-2"
            >
            <v-card-title style="backgroundColor:#E39E9C">
              <h6 class="white--text">Rechazados</h6>
              <v-spacer></v-spacer>
                <v-avatar color="red">
                  <v-icon dark> mdi-briefcase-remove-outline </v-icon>
                </v-avatar>
                
                
              </v-card-title>
          <v-row>
            <v-col cols="3" lg="3" style="font-size:12px;">
              <v-row style="padding-left:15px; font-weight: bold;">
                <v-col>
                  Proyectos
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Afectos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito:       {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit:     {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total:     {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Exentos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:15px; font-weight: bold;">
                <v-col>
                  ITO
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Afectos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
              <v-row style="padding-left:30px; font-weight: bold;">
                <v-col>
                  Exentos
                </v-col>
              </v-row>
              <v-row style="padding-left:40px;">
                <v-col>
                  Cantidad: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).length }}<br>
                  Proyectos: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0).toLocaleString("es-CL") }}<br>
                  Ito: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0).toLocaleString("es-CL") }}<br>
                  Revit: {{ this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0).toLocaleString("es-CL") }}<br>
                  <b>Total: {{ (this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorProyectos,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + curr.valorIto,0) + this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,4) === `${this.anoSeleccionado}`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0)).toLocaleString("es-CL") }}</b>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9" lg="9">
              <canvas style="backgroundColor: #FFFFFF" id="presupuestos_graph_rechazados" height="500"></canvas>
            </v-col>
          </v-row>
          </v-card>
      </div>
      <div>
      
    </div>
    </div>
    
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Chart from "chart.js"

//Definimos la clase de los elementos

export default {
  data() {
    return {
      loading: true,
      pres_aprobados: 0,
      pres_aprobados_pesos: 0,
      pres_aprobados_uf: 0,
      pres_pendientes: 0,
      pres_pendientes_pesos: 0,
      pres_pendientes_uf: 0,
      pres_rechazados: 0,
      pres_rechazados_pesos: 0,
      pres_rechazados_uf: 0,

      fact_facturado: 0,
      fact_pendientes: 0,
      fact_pendientes_data: [],

      aniosDisponibles: [],
      anoSeleccionado: new Date().getFullYear(),


      pres_aprobados_proyectos_afectos_proy: [],
      pres_aprobados_proyectos_afectos_revit: [],

      fetch_data_presupuestos: [],
      

      presupuestos_chart: null,
      presupuestos_chart_data: {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          datasets: [],
        },
        options: {
          responsive: true,
          lineTension: 1,
          maintainAspectRatio: false,
          /*scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },*/
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          legend: {
            display: true,
            labels: {
              fontColor: "rgb(255, 99, 132)",
            },
          },
          title: {
            display: false,
            text: "Custom Chart Title",
          },
        }
      },
      presupuestos_chart_pendientes: null,
      presupuestos_chart_data_pendientes: {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          datasets: [],
        },
        options: {
          responsive: true,
          lineTension: 1,
          maintainAspectRatio: false,
          /*scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },*/
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          legend: {
            display: true,
            labels: {
              fontColor: "rgb(255, 99, 132)",
            },
          },
          title: {
            display: false,
            text: "Custom Chart Title",
          },
        }
      },
      presupuestos_chart_rechazados: null,
      presupuestos_chart_data_rechazados: {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          datasets: [],
        },
        options: {
          responsive: true,
          lineTension: 1,
          maintainAspectRatio: false,
          /*scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },*/
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
          legend: {
            display: true,
            labels: {
              fontColor: "rgb(255, 99, 132)",
            },
          },
          title: {
            display: false,
            text: "Custom Chart Title",
          },
        }
      }
    };
  },

  computed: {
    ...mapState(["token", "usuarioDB"]),
    aniosArraySort: function () {
      function compare(a, b) {
        if (a.ano < b.ano) return 1;
        if (a.ano > b.ano) return -1;
        return 0;
      }

      return this.aniosDisponibles.sort(compare);
    },
  },
  methods: {
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios.get("/users/", config).then((response) => {
        this.desserts = response.data;
        this.loading = false;
      });
    },
    async getDataPresupuestos() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/presupuestos/presupuesto/", config).then((response) => {
          var dataResponse = response.data;
          this.fetch_data_presupuestos = response.data;
          console.log(response.data);

          for (const arr of response.data) {
            let date = new Date(Date.parse(arr.fecha_envio));
            let year = date.getFullYear();
            var existeAno = this.aniosDisponibles.findIndex((xa) => xa.ano == year);
            if (existeAno === -1) this.aniosDisponibles.push({ ano: String(year) });
          }


          /*
            nuevas graficas
          */

          this.pres_aprobados_proyectos_afectos_proy = dataResponse.filter((e) => e.status == "2" && e.fecha_adjudicacion.includes(this.anoSeleccionado))


          /*console.log(this.pres_aprobados_proyectos_afectos_proy.reduce((a, b) => 
            a + b.valor
          ,0));*/


          /*
            fin nuevas graficas
          */






          this.pres_aprobados = dataResponse.filter(
            (e) => e.status == "2" && e.fecha_envio.includes(this.anoSeleccionado)
          ).length;
          this.pres_pendientes = dataResponse.filter(
            (e) => e.status == "1" && e.fecha_envio.includes(this.anoSeleccionado)
          ).length;
          this.pres_rechazados = dataResponse.filter(
            (e) => e.status == "3" && e.fecha_envio.includes(this.anoSeleccionado)
          ).length;

          this.pres_aprobados_uf = dataResponse
            .filter(
              (e) => e.status == "2" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "1")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_aprobados_pesos = dataResponse
            .filter(
              (e) => e.status == "2" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "2")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_pendientes_uf = dataResponse
            .filter(
              (e) => e.status == "1" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "1")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_pendientes_pesos = dataResponse
            .filter(
              (e) => e.status == "1" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "2")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_rechazados_uf = dataResponse
            .filter(
              (e) => e.status == "3" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "1")
            .reduce((acu, actual) => acu + actual.valor, 0);

          this.pres_rechazados_pesos = dataResponse
            .filter(
              (e) => e.status == "3" && e.fecha_envio.includes(this.anoSeleccionado)
            )
            .filter((e) => e.tipo_moneda == "2")
            .reduce((acu, actual) => acu + actual.valor, 0);

          resolve("ok");
        });
      });
    },
    async getDataFacturas() {
      new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/facturas/factura/", config).then((response) => {
          var dataResponse = response.data;

          const timeElapsed = Date.now();
          const today = new Date(timeElapsed);

          let hoy = today.toISOString().substr(0, 7);

          this.fact_facturado = dataResponse
            .filter((e) => e.fecha_factura.substr(0, 7) == hoy && e.status != 3)
            .reduce((acu, actual) => acu + actual.monto, 0);
          //console.log(rsg);

          resolve("ok");
        });
      });
    },
    async getDataPendientes() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/proyectos/todosSeguimientos/", config).then((response) => {
          //console.log()
          console.log('prev');
          console.log(response);
          
          var dataResponse = response.data;
          var responsefinal = [];
          for (let dato in dataResponse) {
            for (let concepto of dataResponse[dato].condiciones) {
              responsefinal.push({
                _id: dataResponse[dato]._id,

                //nro_factura: filtro.fecha_normal(concepto.fecha_realizacion),
                monto: concepto.monto,
                moneda: dataResponse[dato].presupuestos[0].tipo_moneda,
                exento: dataResponse[dato].presupuestos[0]?.exento,
                status: concepto.estado,
              });
            }
          }
          this.fact_pendientes = responsefinal.filter((e) => e.status == 2).length;
          this.fact_pendientes_data = responsefinal.filter((e) => e.status == 2);
          console.log(responsefinal);
          console.log('fin');

          this.loading = false;
        });
      });
    },
    async createChart(chartId, chartData){
      console.log('bien');
      console.log(chartId);

      const ctx = document.getElementById(chartId);
      console.log(chartData.type);
      this.presupuestos_chart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },
    async createChartPendientes(chartId, chartData){
      console.log('bien');
      console.log(chartId);

      const ctx = document.getElementById(chartId);
      console.log(chartData.type);
      this.presupuestos_chart_pendientes = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },
    async createChartRechazados(chartId, chartData){
      console.log('bien');
      console.log(chartId);

      const ctx = document.getElementById(chartId);
      console.log(chartData.type);
      this.presupuestos_chart_rechazados = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },
    async cargarDatos(){
      console.log('llego');
      this.presupuestos_chart_data.data.datasets = [];

      
      console.log(`${this.anoSeleccionado}-01`);
      console.log(this.pres_aprobados_proyectos_afectos_proy);
      console.log('fin')

      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto Proy",
        backgroundColor: "rgba(138, 43, 226)",
        borderColor: "rgba(138, 43, 226)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });

      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto ITO",
        backgroundColor: "rgba(128, 0, 0)",
        borderColor: "rgba(128, 0, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });
      //console.log(this.presupuestos_chart.data.datasets);

      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto Revit",
        backgroundColor: "rgba(255, 127, 80)",
        borderColor: "rgba(255, 127, 80)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });

      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "Proyectos exento Proy",
        backgroundColor: "rgba(0, 255, 255)",
        borderColor: "rgba(0, 255, 255)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });

      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "Proyectos exento ITO",
        backgroundColor: "rgba(107, 142, 35)",
        borderColor: "rgba(107, 142, 35)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });

      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "Proyectos exento Revit",
        backgroundColor: "rgba(220, 20, 60)",
        borderColor: "rgba(220, 20, 60)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "1" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });
      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "ITO afecto Ito",
        backgroundColor: "rgba(0, 0, 139)",
        borderColor: "rgba(0, 0, 139)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });

      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "ITO afecto Proy",
        backgroundColor: "rgba(216, 191, 216)",
        borderColor: "rgba(216, 191, 216)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });
      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "ITO afecto Revit",
        backgroundColor: "rgba(189, 183, 107)",
        borderColor: "rgba(189, 183, 107)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && !e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });
      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "ITO exento Ito",
        backgroundColor: "rgba(72, 61, 139)",
        borderColor: "rgba(72, 61, 139)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });
      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "ITO exento Proy",
        backgroundColor: "rgba(255, 255, 0)",
        borderColor: "rgba(255, 255, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });
      this.presupuestos_chart.data.datasets.push({
        type: "bar",
        label: "ITO exento Revit",
        backgroundColor: "rgba(0, 128, 0)",
        borderColor: "rgba(0, 128, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "2" && e.exento && e.tipo === "2" && e.fecha_adjudicacion.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });

      this.presupuestos_chart.update();
      this.presupuestos_chart.resize();

      console.log('llego2');
      this.presupuestos_chart_data_pendientes.data.datasets = [];

      
      console.log(`${this.anoSeleccionado}-01`);
      console.log(this.pres_aprobados_proyectos_afectos_proy);
      console.log('fin')

      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto Proy",
        backgroundColor: "rgba(138, 43, 226)",
        borderColor: "rgba(138, 43, 226)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto ITO",
        backgroundColor: "rgba(128, 0, 0)",
        borderColor: "rgba(128, 0, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });
      //console.log(this.presupuestos_chart.data.datasets);

      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto Revit",
        backgroundColor: "rgba(255, 127, 80)",
        borderColor: "rgba(255, 127, 80)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "Proyectos exento Proy",
        backgroundColor: "rgba(0, 255, 255)",
        borderColor: "rgba(0, 255, 255)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "Proyectos exento ITO",
        backgroundColor: "rgba(107, 142, 35)",
        borderColor: "rgba(107, 142, 35)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "Proyectos exento Revit",
        backgroundColor: "rgba(220, 20, 60)",
        borderColor: "rgba(220, 20, 60)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "ITO afecto Ito",
        backgroundColor: "rgba(0, 0, 139)",
        borderColor: "rgba(0, 0, 139)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "ITO afecto Proy",
        backgroundColor: "rgba(216, 191, 216)",
        borderColor: "rgba(216, 191, 216)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "ITO afecto Revit",
        backgroundColor: "rgba(189, 183, 107)",
        borderColor: "rgba(189, 183, 107)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "ITO exento Ito",
        backgroundColor: "rgba(72, 61, 139)",
        borderColor: "rgba(72, 61, 139)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "ITO exento Proy",
        backgroundColor: "rgba(255, 255, 0)",
        borderColor: "rgba(255, 255, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_pendientes.data.datasets.push({
        type: "bar",
        label: "ITO exento Revit",
        backgroundColor: "rgba(0, 128, 0)",
        borderColor: "rgba(0, 128, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "1" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });

      this.presupuestos_chart_pendientes.update();
      this.presupuestos_chart_pendientes.resize();

      console.log('llego3');
      this.presupuestos_chart_data_rechazados.data.datasets = [];

      
      console.log(`${this.anoSeleccionado}-01`);
      console.log(this.pres_aprobados_proyectos_afectos_proy);
      console.log('fin')

      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto Proy",
        backgroundColor: "rgba(138, 43, 226)",
        borderColor: "rgba(138, 43, 226)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto ITO",
        backgroundColor: "rgba(128, 0, 0)",
        borderColor: "rgba(128, 0, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });
      //console.log(this.presupuestos_chart.data.datasets);

      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "Proyectos afecto Revit",
        backgroundColor: "rgba(255, 127, 80)",
        borderColor: "rgba(255, 127, 80)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "Proyectos exento Proy",
        backgroundColor: "rgba(0, 255, 255)",
        borderColor: "rgba(0, 255, 255)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "Proyectos exento ITO",
        backgroundColor: "rgba(107, 142, 35)",
        borderColor: "rgba(107, 142, 35)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "Proyectos exento Revit",
        backgroundColor: "rgba(220, 20, 60)",
        borderColor: "rgba(220, 20, 60)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "1" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "ITO afecto Ito",
        backgroundColor: "rgba(0, 0, 139)",
        borderColor: "rgba(0, 0, 139)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });

      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "ITO afecto Proy",
        backgroundColor: "rgba(216, 191, 216)",
        borderColor: "rgba(216, 191, 216)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "ITO afecto Revit",
        backgroundColor: "rgba(189, 183, 107)",
        borderColor: "rgba(189, 183, 107)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && !e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "ITO exento Ito",
        backgroundColor: "rgba(72, 61, 139)",
        borderColor: "rgba(72, 61, 139)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorIto,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorIto,0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "ITO exento Proy",
        backgroundColor: "rgba(255, 255, 0)",
        borderColor: "rgba(255, 255, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + curr.valorProyectos,0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + curr.valorProyectos,0),
        ],
        order: 0,
      });
      this.presupuestos_chart_data_rechazados.data.datasets.push({
        type: "bar",
        label: "ITO exento Revit",
        backgroundColor: "rgba(0, 128, 0)",
        borderColor: "rgba(0, 128, 0)",
        data: [
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-01`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-02`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-03`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-04`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-05`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-06`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-07`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-08`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-09`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-10`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-11`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
          this.fetch_data_presupuestos.filter(e => e.tipo_moneda === "1" && e.status == "3" && e.exento && e.tipo === "2" && e.fecha_envio.substr(0,7) === `${this.anoSeleccionado}-12`).reduce((acc, curr) => acc + ((curr.valorRevit) ? curr.valorRevit : 0),0),
        ],
        order: 0,
      });

      this.presupuestos_chart_rechazados.update();
      this.presupuestos_chart_rechazados.resize();
    }
  },
  
  mounted: async function(){
    console.log('montado');
    await this.getDataPresupuestos();
    await this.createChart('presupuestos_graph', this.presupuestos_chart_data);
    await this.createChartPendientes('presupuestos_graph_pendientes', this.presupuestos_chart_data_pendientes);
    await this.createChartRechazados('presupuestos_graph_rechazados', this.presupuestos_chart_data_rechazados);
    await this.cargarDatos();
  },
  async created() {
    //this.getData();    
    

    
    await this.getDataFacturas();
    await this.getDataPendientes();
    //
  },
  watch: {
    async anoSeleccionado(v) {
      //this.verPagos = 0;
      await this.getDataPresupuestos();
      await this.cargarDatos();
    },
  },
};
</script>
<style>
.card-actions {
  position: absolute;
  bottom: 0;
  width: "100%";
}
</style>
